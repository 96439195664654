<template>
	<article class="lp" id="js_pagetop">
		<h1 class="lp__header"><img src="~@/assets/img/lp_winterhealthcare/title.png" alt="冬のココロの健康管理～太陽の光でポジティブに～ presented by 頭痛ーる"></h1>

		<!-- イントロ -->
		<section class="lp__intro">
			<h2 class="lp__intro--title">
				<p class="lp__intro--img"><img src="~@/assets/img/lp_winterhealthcare/main.png" alt="メインイメージ"></p>
				<p class="lp__intro--img"><img src="~@/assets/img/lp_winterhealthcare/top_subtitle01.png" alt="冬に起こりやすい不調"></p>
			</h2>
			<p class="lp__text lp__intro--text">
				日に日に早まる晩秋~初冬の夕暮れ。日ざしが弱まり、昼の時間が短くなっていくこの時期、<em>気分が落ち込み</em>がちだったり、<em>寝つきが悪い</em>ことはありませんか?<br>
				じつはコレ、<em>日ざしの不足が心や体の健康に大きな影響を与えている</em>ことが多いのです!<br><br>
				<i class="lp__intro--icon" />最後まで読んでくれた人にはクリスマス＆正月スタンプのプレゼントがあります。
			</p>
			<p class="lp__maro lp__intro--comment"><span>まずは今の状態を確認してみるニャ！</span></p>
			<a class="lp__button lp__stresscheck--button lp__intro--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022TOPリンク1" data-gtm-event-act-label="trackingLpWinterHelthcare2022topBtn01" href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stresscheckDiagnosis` } })">
				<img src="~@/assets/img/lp_winterhealthcare/stresscheck_banner.png" alt="1分で診断! 医師監修 今の自分がわかるストレスチェック">
			</a>
			<p class="lp__intro--annotation lp__stresscheck--annotation"><small>監修:アイさくらクリニック 木村昌幹 先生</small></p>
		</section>
		<!-- /イントロ -->

		<!-- コンテンツ -->
		<section class="lp__contents--wrapper">
			<h2 class="lp__contents--title"><img src="~@/assets/img/lp_winterhealthcare/top_subtitle02.png" alt="冬の不調を予防！知って得する3つのポイント"></h2>
			<p class="lp__text lp__contents--text">太陽の光の量と心の健康には密接な関係があります。<em>うつや不眠予防につながる3つのポイント</em>を早速チェックしてみましょう！</p>

			<!-- ポイント1 -->
			<section class="lp__contents is-point1" id="js_point1">
				<h3 class="lp__contents--title">
					<span><img src="~@/assets/img/lp_winterhealthcare/point01.png" alt="ポイント1"></span>
					<p>冬に心の不調が起きる原因</p>
					<i class="lp__line" />
				</h3>
				<p class="lp__text lp__contents--text">冬になると日照の減少とともに、体に影響が出て様々な不調が起こる事があります。不調の原因を知る事で対策に役立てましょう。</p>
				<a class="lp__button lp__contents--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022TOPリンク2" data-gtm-event-act-label="trackingLpWinterHelthcare2022topBtn02"  href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpwinterhealthcare2022cause' } })"><span>不調の原因を見てみる</span></a>
			</section>
			<!-- /ポイント1 -->

			<!-- ポイント2 -->
			<section class="lp__contents is-point2" id="js_point2">
				<h3 class="lp__contents--title">
					<span><img src="~@/assets/img/lp_winterhealthcare/point02.png" alt="ポイント2"></span>
					<p>今すぐ実践！うつ・不眠予防!!</p>
					<i class="lp__line" />
				</h3>
				<p class="lp__text lp__contents--text">すぐに実践できる3つの対策を紹介します。毎朝の習慣に取り入れる事で不調になりにくい生活をおくりましょう。</p>
				<a class="lp__button lp__contents--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022TOPリンク3" data-gtm-event-act-label="trackingLpWinterHelthcare2022topBtn03"  href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=lp`, _lpType: 'lpwinterhealthcare2022habit' } })"><span>3つの対策を見てみる</span></a>
			</section>
			<!-- /ポイント2 -->

			<!-- ポイント3 -->
			<section class="lp__contents is-point3" id="js_point3">
				<h3 class="lp__contents--title">
					<span><img src="~@/assets/img/lp_winterhealthcare/point03.png" alt="ポイント3"></span>
					<p>うつ指数で体調管理</p>
					<i class="lp__line" />
				</h3>
				<p class="lp__text lp__contents--text">うつ指数とは、日ざし(天気)と気圧変化から5段階の注意レベルで表しています。「健康天気指数」に提供中コンテンツですので確認してみてください。</p>
				<p class="lp__contents--img"><img src="~@/assets/img/lp_winterhealthcare/top_img01.png" alt="健康天気指数イメージ"></p>
				<p class="lp__maro lp__contents--comment"><span>うつ指数のチェックを毎日の習慣にしてみてニャ！</span></p>
				<a class="lp__button lp__contents--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId }, hash: '#js_healthweather' })"><span class="is-small">頭痛ーるで毎日更新！</span><span class="is-large">うつ指数をチェック</span></a>
				<p class="lp__hiroshi lp__app--comment is-margin20"><span>アプリでも見られるのじゃよ。</span></p>
				<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">チェックする</span></a>
				<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">チェックする</span></a>

				<!-- アプリの天気予報 -->
				<section class="lp__app is-weather-forecast" id="js_weatherForecast">
					<h4 class="lp__app--title"><em>頭痛ーるアプリの天気予報</em></h4>
					<p class="lp__text lp__app--text">頭痛ーるアプリは天気に加えて、日の出、日の入り時間まで確認する事ができますので朝の生活習慣の手助けになります。</p>
					<p class="lp__app--img"><img src="~@/assets/img/lp_winterhealthcare/app_img01.png" alt="天気予報イメージ"></p>
					<p class="lp__hiroshi lp__app--comment"><span>日の出の時間と朝の天気を<br>チェックじゃ！</span></p>
					<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
					<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">天気予報をチェックする</span></a>
				</section>
				<!-- /アプリの天気予報 -->

				<!-- アプリの記録機能 -->
				<section class="lp__app is-recording-function" id="js_recordingFunction">
					<h4 class="lp__app--title"><em>頭痛ーるアプリの記録機能</em></h4>
					<p class="lp__text lp__app--text">頭痛ーるアプリば頭痛の痛みだけでなく、体調や飲んだ薬をアプリで記録でき、体調管理に役立てる事ができます。</p>
					<p class="lp__app--img"><img src="~@/assets/img/lp_winterhealthcare/app_img02.png" alt="記録イメージ"></p>
					<p class="lp__hiroshi is-tight lp__app--comment"><span>自分が不調になりやすいタイミングが分かりそうじゃ！</span></p>
					<a v-if="isAndroid" class="lp__button lp__app--button" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">記録する</span></a>
					<a v-else class="lp__button lp__app--button" href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })"><span class="is-small">頭痛ーるアプリで</span><span class="is-large">記録する</span></a>
				</section>
				<!-- /アプリの記録機能 -->
			</section>
			<!-- /ポイント3 -->
		</section>
		<!-- /コンテンツ -->

		<!-- マロ -->
		<aside class="lp__maro lp__comment"><span>最後まで見てくれてありがとニャ！</span></aside>
		<!-- /マロ -->

		<!-- スタンププレゼント -->
		<section class="lp__stamp" id="js_stamp">
			<div class="lp__stamp--inner">
				<h2 class="lp__stamp--title"><img src="@/assets/img/lp_winterhealthcare/stamp_title.png" alt="＼スゴ得限定／ クリスマス&amp;お正月 スタンププレゼント"></h2>
				<p class="lp__stamp--img"><img src="@/assets/img/lp_winterhealthcare/stamp.png" alt=""></p>
				<a class="lp__stamp--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022TOPリンク4" data-gtm-event-act-label="trackingLpWinterHelthcare2022topBtn04"  href="javascript: void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=stamp`, _stampType: 'xmas', _backPage: 'top' } })">プレゼントをもらう</a>
			</div>
		</section>
		<!-- /スタンププレゼント -->

		<!-- アウトロ -->
		<section class="lp__outro">
			<h2 class="lp__outro--title">太陽の光を味方に<br>”with頭痛ーるアプリ”で<br>ココロも体も健康な冬に</h2>
			<div class="lp__outro--img"><img src="@/assets/img/lp_winterhealthcare/outro.png" alt="マロと雪だるま"></div>
			<a href="javascript:void(0);" @click.prevent="onScroll('js_pagetop')" class="lp__outro--button is-pagetop">上部にもどる</a>
			<a href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { id: sugotokuContentsId } })" class="lp__outro--button is-back">TOPにもどる</a>
		</section>
		<!-- /アウトロ -->

		<!-- 下に吸着するアプリダウンロードボタン -->
		<aside class="sticky-bottom-appdownload">
			<a v-if="isAndroid" href="dcmdam://launch?url=http%3A%2F%2Fapp-manager.docomo.ne.jp%2FSpApps%2FdetailApp%3Fdcmstore_view%3Dnone%26cId%3D10000023012" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022TOPリンク5Android" data-gtm-event-act-label="trackingLpWinterHelthcare2022topBtn05_android"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
			<a v-else href="javascript:void(0);" @click.prevent="$router.push({ name: 'Cpsite', query: { url: `${backendUrl}?_path=appDownload`, _backPage: 'top' } })" class="sticky-bottom-appdownload--button gtm-link_click_count01" data-gtm-event-cat-label="冬の健康管理特集ページ2022TOPリンク5iOS" data-gtm-event-act-label="trackingLpWinterHelthcare2022topBtn05_ios"><img src="~@/assets/img/sugotoku_app_info_btn03.png" alt="今すぐダウンロード！"></a>
		</aside>
		<!-- /下に吸着するアプリダウンロードボタン -->
	</article>
</template>

<script>
// Vuex
import { mapGetters } from 'vuex'

// ライブラリ
import cmnConst from '@/assets/js/constant.js'

export default {
	name: 'LpWinterHealthcare2022Top',
	components: {
	},
	data () {
		return {
			backendUrl: cmnConst.BACKEND_URL,
			sugotokuContentsId: cmnConst.SUGOTOKU_CONTENTS_ID,
			$html: null
		}
	},
	computed: {
		// map Vuex getters
		...mapGetters('common', ['isAndroid'])
	},
	created () {
	},
	mounted () {
		// 横幅に合わせてフォントサイズや余白をレスポンシブに可変する対応
		// TODO: Webview、スゴ得でリポジトリをわける際、common.scssでルートフォントをvwで指定するようにしたい。もしできたらこちらの処理は不要なので削除。
		const propWidth = document.body.offsetWidth
		const style = propWidth <= 640 ? 'font-size: 16px; font-size: 4.267vw;' : 'font-size: 27px;'
		this.$html = document.getElementsByTagName('html')[0]
		this.$html.setAttribute('style', style)

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		if (this.$route.hash) window.addEventListener('load', this.hashAnchorLink)
	},
	beforeDestroy () {
		window.removeEventListener('load', this.hashAnchorLink)
		this.$html.removeAttribute('style')
	},
	methods: {
		// ページスクロール用の関数
		onScroll (id) {
			const elem = document.getElementById(id)
			const options = {
				container: 'body',
				duration: 500,
				easing: 'ease-out',
				offset: 0,
				cancelable: false,
				onStart: false,
				onDone: false,
				onCancel: false,
				x: false,
				y: true
			}
			this.$scrollTo(elem, options)
		},

		// ページ遷移後のアンカーリンク
		// NOTE: 今後統一する予定
		hashAnchorLink () {
			const $elem = document.querySelector(this.$route.hash)
			if ($elem) $elem.scrollIntoView ? $elem.scrollIntoView({ behavior: 'smooth', block: 'start' }) : scrollTo(0, $elem.getBoundingClientRect().top)
		}
	}
}
</script>

<style lang="scss" scoped>
// 特集共通SASS
@import "~@/assets/sass/lp_winterhealthcare/lp_common";

.lp {
	&__intro {
		width: 100%;

		&--title { margin: 0; }

		&--img { margin: $spacing-10 0 0; }

		&--img:first-of-type {
			width: 85.3334%;
			margin: 0 auto;
		}

		&--text { padding: $spacing-10 $spacing-14 0; }

		&--icon {
			display: inline-block;
			width: 4.0116%;
			padding-top: 4.9252%;
			background: url(~@/assets/img/lp_winterhealthcare/icon01.png) no-repeat 0 0;
			background-size: 100% auto;
		}

		&--comment {
			width: 81.866%;
			padding-top: 16.944%;
			margin: $spacing-12 auto 0;
		}

		&--button { margin: $spacing-10 auto 0; }

		&--annotation { margin: $spacing-9 0 0; }
	}

	&__contents {
		&--wrapper {
			margin: $spacing-20 0 0 0;

			& > .lp__contents--title { margin: 0; }
			& > .lp__contents--text { padding: $spacing-10 $spacing-14 0; }
		}

		&.is-point1 { margin-top: $spacing-12; }
		&.is-point3 {
			margin-top: $spacing-19;
			padding-bottom: $spacing-20;
		}
	}

	&__app.is-weather-forecast { margin: $spacing-24 auto 0; }

	&__comment {
		width: 82.1334%;
		margin: $spacing-20 auto 0;
		padding-top: 16.944%;

		& > span {
			width: 62.9871%;
			top: $spacing-8;
		}
	}

	&__outro {
		&--button.is-pagetop { margin-top: $spacing-36; }
	}

	&__stamp {
		padding: 8% 0 16%;
		margin: $spacing-10 0 0 0;
		background: url(~@/assets/img/lp_winterhealthcare/stamp_bg_top.png) no-repeat 0 0, url(~@/assets/img/lp_winterhealthcare/stamp_bg_bottom.png) no-repeat 0 100%;
		background-size: 100% auto, 100% auto;

		&--inner {
			padding: $spacing-5 0 $spacing-16;
			background-color: $lp-stamp-backgroud-color;
		}

		&--title { margin: 0 auto; }

		&--img {
			width: 82.9334%;
			margin: $spacing-20 auto 0;
		}

		&--button {
			display: block;
			width: 78.6667%;
			margin: $spacing-20 auto 0;
			padding: $spacing-10 0;
			border-radius: 6px;
			font-family: $lp-family-secondary;
			color: $text-seconday;
			text-align: center;
			text-decoration: none;
			background-color: $lp-stamp-button-color;
			font-size: $font-size-18;
			box-shadow: 0 3px 0 0 $lp-stamp-button-border-color;
		}
	}
}

.is-margin20 {
	margin: $spacing-20 auto 0;
}
</style>
